import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/modus-react-bootstrap/modus-react-bootstrap/docs/src/layouts/MainLayout.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <article id="main" className="pr-3">
  <h2 id="overview" className="h1 font-weight-bold mt-5">
    Overview
    <a className="header-link text-light text-decoration-none font-weight-normal" href="#overview" aria-label="anchor"></a>
  </h2>
  <p>
    Each set of colors has its own set of utility classes that modify background
    color, font color, and border color. You can reference the available utility
    classes below:
  </p>
  <h3 id="modus-bootstrap-palette" className="h2 font-weight-bold mt-3">
    Modus Bootstrap Palette
    <a className="header-link text-light text-decoration-none font-weight-normal" href="#modus-bootstrap-palette" aria-label="anchor"></a>
  </h3>
  <div className="row">
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-primary">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Primary Color</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #0063a3
          </div>
          <code className="d-block small mr-1">.bg-primary</code>
          <code className="d-block small mr-1">.text-primary</code>
          <code className="d-block small mr-1">.border-primary</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-secondary">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Secondary Color</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #6a6e79
          </div>
          <code className="d-block small mr-1">.bg-secondary</code>
          <code className="d-block small mr-1">.text-secondary</code>
          <code className="d-block small mr-1">.border-secondary</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-tertiary">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Tertiary Color</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #cbcdd6
          </div>
          <code className="d-block small mr-1">.bg-tertiary</code>
          <code className="d-block small mr-1">.text-tertiary</code>
          <code className="d-block small mr-1">.border-tertiary</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-dark">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Dark Color</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #252a2e
          </div>
          <code className="d-block small mr-1">.bg-dark</code>
          <code className="d-block small mr-1">.text-dark</code>
          <code className="d-block small mr-1">.border-dark</code>
        </div>
      </div>
    </div>
  </div>
  <h3 id="primary-palette" className="h2 font-weight-bold mt-3">
    Primary Palette
    <a className="header-link text-light text-decoration-none font-weight-normal" href="#primary-palette" aria-label="anchor"></a>
  </h3>
  <div className="row">
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-trimble-blue-dark">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Trimble Blue Dark</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #004f83
          </div>
          <code className="d-block small mr-1">.bg-trimble-blue-dark</code>
          <code className="d-block small mr-1">.text-trimble-blue-dark</code>
          <code className="d-block small mr-1">.border-trimble-blue-dark</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-trimble-blue">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Trimble Blue</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #0063a3
          </div>
          <code className="d-block small mr-1">.bg-trimble-blue</code>
          <code className="d-block small mr-1">.text-trimble-blue</code>
          <code className="d-block small mr-1">.border-trimble-blue</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-trimble-yellow">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Trimble Yellow</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #fbad26
          </div>
          <code className="d-block small mr-1">.bg-trimble-yellow</code>
          <code className="d-block small mr-1">.text-trimble-yellow</code>
          <code className="d-block small mr-1">.border-trimble-yellow</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-trimble-gray">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Trimble Gray</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #252A2E
          </div>
          <code className="d-block small mr-1">.bg-trimble-gray</code>
          <code className="d-block small mr-1">.text-trimble-gray</code>
          <code className="d-block small mr-1">.border-trimble-gray</code>
        </div>
      </div>
    </div>
  </div>
  <h3 id="blue-progression" className="h2 font-weight-bold mt-3">
    Blue Progression
    <a className="header-link text-light text-decoration-none font-weight-normal" href="#blue-progression" aria-label="anchor"></a>
  </h3>
  <div className="row">
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top" style={{
              backgroundColor: "#0e416c"
            }}>
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Blue Dark</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #0e416c
          </div>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-primary">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Blue</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #0063a3
          </div>
          <code className="d-block small mr-1">.bg-primary</code>
          <code className="d-block small mr-1">.text-primary</code>
          <code className="d-block small mr-1">.border-primary</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-active" style={{
              backgroundColor: "#217cbb"
            }}>
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Blue Light</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #217cbb
          </div>
          <code className="d-block small mr-1">.bg-active</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-selected" style={{
              backgroundColor: "#dcedf9"
            }}>
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Blue Pale</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #dcedf9
          </div>
          <code className="d-block small mr-1">.bg-selected</code>
        </div>
      </div>
    </div>
  </div>
  <h3 id="neutral-progression" className="h2 font-weight-bold mt-3">
    Neutral Progression
    <a className="header-link text-light text-decoration-none font-weight-normal" href="#neutral-progression" aria-label="anchor"></a>
  </h3>
  <p>Various shades of gray for utility.</p>
  <div className="row">
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-black">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Black</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #000000
          </div>
          <code className="d-block small mr-1">.bg-black</code>
          <code className="d-block small mr-1">.text-black</code>
          <code className="d-block small mr-1">.border-black</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-trimble-gray">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Trimble Gray</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #252A2E
          </div>
          <code className="d-block small mr-1">.bg-trimble-gray</code>
          <code className="d-block small mr-1">.text-trimble-gray</code>
          <code className="d-block small mr-1">.border-trimble-gray</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-gray-10">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Gray 10</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #171C1E
          </div>
          <code className="d-block small mr-1">.bg-gray-10</code>
          <code className="d-block small mr-1">.text-gray-10</code>
          <code className="d-block small mr-1">.border-gray-10</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-gray-9">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Gray 9</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #353A40
          </div>
          <code className="d-block small mr-1">.bg-gray-9</code>
          <code className="d-block small mr-1">.text-gray-9</code>
          <code className="d-block small mr-1">.border-gray-9</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-gray-8">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Gray 8</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #464B52
          </div>
          <code className="d-block small mr-1">.bg-gray-8</code>
          <code className="d-block small mr-1">.text-gray-8</code>
          <code className="d-block small mr-1">.border-gray-8</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-gray-7">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Gray 7</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #585C65
          </div>
          <code className="d-block small mr-1">.bg-gray-7</code>
          <code className="d-block small mr-1">.text-gray-7</code>
          <code className="d-block small mr-1">.border-gray-7</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-gray-6">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Gray 6</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #6A6E79
          </div>
          <code className="d-block small mr-1">.bg-gray-6</code>
          <code className="d-block small mr-1">.text-gray-6</code>
          <code className="d-block small mr-1">.border-gray-6</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-gray-5">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Gray 5</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #7d808d
          </div>
          <code className="d-block small mr-1">.bg-gray-5</code>
          <code className="d-block small mr-1">.text-gray-5</code>
          <code className="d-block small mr-1">.border-gray-5</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-gray-4">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Gray 4</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #90939F
          </div>
          <code className="d-block small mr-1">.bg-gray-4</code>
          <code className="d-block small mr-1">.text-gray-4</code>
          <code className="d-block small mr-1">.border-gray-4</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-gray-3">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Gray 3</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #a3a6b1
          </div>
          <code className="d-block small mr-1">.bg-gray-3</code>
          <code className="d-block small mr-1">.text-gray-3</code>
          <code className="d-block small mr-1">.border-gray-3</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-gray-2">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Gray 2</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #b7b9c3
          </div>
          <code className="d-block small mr-1">.bg-gray-2</code>
          <code className="d-block small mr-1">.text-gray-2</code>
          <code className="d-block small mr-1">.border-gray-2</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-gray-1">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Gray 1</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #cbcdd6
          </div>
          <code className="d-block small mr-1">.bg-gray-1</code>
          <code className="d-block small mr-1">.text-gray-1</code>
          <code className="d-block small mr-1">.border-gray-1</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-gray-0">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Gray 0</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #e0e1e9
          </div>
          <code className="d-block small mr-1">.bg-gray-0</code>
          <code className="d-block small mr-1">.text-gray-0</code>
          <code className="d-block small mr-1">.border-gray-0</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-gray-light">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Gray Light</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #F1F1F6
          </div>
          <code className="d-block small mr-1">.bg-gray-light</code>
          <code className="d-block small mr-1">.text-gray-light</code>
          <code className="d-block small mr-1">.border-gray-light</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-white">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">White</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #ffffff
          </div>
          <code className="d-block small mr-1">.bg-white</code>
          <code className="d-block small mr-1">.text-white</code>
          <code className="d-block small mr-1">.border-white</code>
        </div>
      </div>
    </div>
  </div>
  <h3 id="yellow-progression" className="h2 font-weight-bold mt-3">
    Yellow Progression
    <a className="header-link text-light text-decoration-none font-weight-normal" href="#yellow-progression" aria-label="anchor"></a>
  </h3>
  <div className="row">
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-warning">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Warning Color</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #e49325
          </div>
          <code className="d-block small mr-1">.bg-warning</code>
          <code className="d-block small mr-1">.text-warning</code>
          <code className="d-block small mr-1">.border-warning</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-trimble-yellow">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Yellow</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #fbad26
          </div>
          <code className="d-block small mr-1">.bg-trimble-yellow</code>
          <code className="d-block small mr-1">.text-trimble-yellow</code>
          <code className="d-block small mr-1">.border-trimble-yellow</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top" style={{
              backgroundColor: "#fec157"
            }}>
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Yellow Light</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #fec157
          </div>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top" style={{
              backgroundColor: "#fff5e4"
            }}>
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Yellow Pale</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #fff5e4
          </div>
        </div>
      </div>
    </div>
  </div>
  <h3 id="red-progression" className="h2 font-weight-bold mt-3">
    Red Progression
    <a className="header-link text-light text-decoration-none font-weight-normal" href="#red-progression" aria-label="anchor"></a>
  </h3>
  <div className="row">
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top" style={{
              backgroundColor: "#ab1f26"
            }}>
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Red Dark</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #ab1f26
          </div>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-danger">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Danger Color</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #da212c
          </div>
          <code className="d-block small mr-1">.bg-danger</code>
          <code className="d-block small mr-1">.text-danger</code>
          <code className="d-block small mr-1">.border-danger</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top" style={{
              backgroundColor: "#e86363"
            }}>
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Red Light</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #e86363
          </div>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top" style={{
              backgroundColor: "#fbd4d7"
            }}>
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Red Pale</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #fbd4d7
          </div>
        </div>
      </div>
    </div>
  </div>
  <h3 id="green-progression" className="h2 font-weight-bold mt-3">
    Green Progression
    <a className="header-link text-light text-decoration-none font-weight-normal" href="#green-progression" aria-label="anchor"></a>
  </h3>
  <div className="row">
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top bg-success">
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Success Color</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #006638
          </div>
          <code className="d-block small mr-1">.bg-success</code>
          <code className="d-block small mr-1">.text-success</code>
          <code className="d-block small mr-1">.border-success</code>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top" style={{
              backgroundColor: "#1e8a44"
            }}>
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Green</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #1e8a44
          </div>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top" style={{
              backgroundColor: "#4ea646"
            }}>
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Green Light</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #4ea646
          </div>
        </div>
      </div>
    </div>
    <div className="col-12 col-sm-6 col-lg-4 col-xl-3 mb-4">
      <div className="card h-100 w-100 shadow-sm">
        <div className="card-img-top" style={{
              backgroundColor: "#e0eccf"
            }}>
          <div className="py-4 py-lg-5"></div>
        </div>
        <div className="card-body pb-2 mb-1">
          <div className="card-title mb-1">
            <h5 className="mb-0 pb-0">Green Pale</h5>
          </div>
          <div className="text-lowercase text-monospace mb-1 h4 text-secondary font-weight-normal">
            #e0eccf
          </div>
        </div>
      </div>
    </div>
  </div>
    </article>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      